import { useCallback, useState } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from "@mui/material/IconButton";
import { Icon } from '@iconify/react';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { bgBlur } from "../../../styles/css";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.shadows,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

const SearchBar = React.memo((props:any) => {
    const [open, setOpen] = useState(false);
    const [textSearch, setTextSearch] = useState<string>("");
    const navigate = useNavigate();
    
    const handleOpen = () => {
        if (!open) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        if (open) {
            setOpen(false);
        }
    };

    const handleKeyPress = async (event:any) => { 
        if (event.key === 'Enter') 
            { 
                navigate(`/search?searchText=${textSearch}`);
                //window.location.href = '/search?searchText=' + textSearch;
            }
    };

    const onChange = useCallback((event:any) => {
        setTextSearch(event.target.value);
    }, []);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!open && (
                <Tooltip title="Buscar estación">
                    <IconButton onClick={handleOpen}>
                        <Icon icon="eva:search-fill" style={{ color: 'black' }}/>
                    </IconButton>
                </Tooltip>
                )}
                <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                    <StyledSearchbar>
                        <Input
                            autoFocus
                            fullWidth
                            disableUnderline
                            placeholder="Buscar estación"
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Icon
                                        icon="eva:search-fill"
                                        style={{ color: 'text.disabled', width: 20, height: 20 }}
                                    />
                                </InputAdornment>
                            }
                            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                        />
                        <Link to={'/search?searchText=' + textSearch} 
                              onClick={handleClose} 
                              style={{ 
                                visibility: textSearch.length > 0 ? 'visible' : 'hidden',
                                display: 'inline-block', 
                                padding: '6px 16px', 
                                color: 'white', 
                                backgroundColor: '#000', 
                                textAlign: 'center', 
                                textDecoration: 'none', 
                                borderRadius: '4px', 
                                transition: 'background-color 0.3s', }}>
                            Buscar
                        </Link>
                    </StyledSearchbar>
                </Slide>
            </div>
        </ClickAwayListener>
    );
});

export default SearchBar;
